.background-img {
  background-color: $app-white;
  border-radius: 3px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.numeric-value {
  position: absolute;
  left: 0;
}
