@import './variables';
@import '~src/scss/breakpoints';

.cdk-drag-preview {
  background-color: $body-bg-color;
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.custom-placeholder {
  opacity: 0;
  position: absolute;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.tiki-word {
  height: 46px;
  // width: 230px;
  width: 180px;
  box-sizing: border-box;
  border: 2px solid $main-accent-color;
  color: $word-color;
  font-size: 28px;
  font-weight: $font-weight-medium;
  text-align: start;
  line-height: 42px;
  border-radius: 3px;
  padding: 0px 16px;
  cursor: move;

  &--disabled {
    pointer-events: none;
    border: solid 2px rgba(174, 181, 185, 0.7);
    color: rgba(174, 181, 185, 0.8);
  }

  &__placeholder {
    top: 0px;
    position: absolute;
    z-index: -1;
    pointer-events: none;
    border: solid 2px rgba(174, 181, 185, 0.7);
    color: rgba(174, 181, 185, 0.8);
  }

  &__dropped {
    border: none;
    color: $app-white;
    background-color: $success-color;
    pointer-events: none;

    &--incorrect {
      background-color: $fail-color;
      pointer-events: all;
    }
  }

  &__drop-zone {
    height: 46px;
    width: 100%;
    box-sizing: border-box;

    &--empty {
      border: dashed 1px rgba(110, 124, 130, 0.51);
      border-radius: 3px;
    }
  }

  p {
    display: inline;
  }

  .katex {
    font-family: $app-primary-font !important;
    font-size: inherit;
    font-weight: inherit;
  }

  div:has(span.katex) {
    text-align: center;
  }
}

.tiki-word:has(span.katex) {
  height: auto;
  padding-bottom: 3px;
  padding-top: 3px;
}

@media (max-width: $full-hd-width) {
  .tiki-word {
    // width: 170px;
    width: 200px;
  }
}

.tiki-image {
  width: $image-width;
  height: $image-height;

  &__img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }

  &__dropped {
    border: none;
    background-color: $app-white;
    border-bottom: 16px solid $success-color;
    pointer-events: none;
    margin-bottom: 0;
    border-radius: 3px;

    img {
      width: inherit;
      height: inherit;
      object-fit: contain;
    }

    &--incorrect {
      border-bottom: 16px solid $fail-color;
      pointer-events: all;
    }
  }

  &__drop-zone {
    width: $image-width;
    height: $image-height;
    box-sizing: border-box;

    &--empty {
      border-radius: 3px;
      border: dashed 2px $border-color;
    }
  }
}
