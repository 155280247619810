@import './variables';

.logo {
  display: inline-block;
  background: url('~src/assets/images/logo.png') center center;
  background-size: cover;
  height: 60px;
  width: 83px;
}

.ico {
  width: $ico-size;
  height: $ico-size;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;

  &:hover {
    box-shadow: 0 2px 6px 1px rgba(209, 201, 189, 0.7);
  }

  &:focus {
    box-shadow: 0 2px 6px 1px rgba(209, 201, 189, 0.7);
  }

  &:active {
    transform: scale(1.1);
  }

  &[disabled] {
    cursor: not-allowed;

    &:hover {
      box-shadow: none;
    }
  }
}

.ico-home {
  background: url('src/assets/ico/home.svg') center center;
}

.ico-profile {
  display: block;
  background: url('./assets/ico/ico-profile.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-en {
  display: block;
  background: url('./assets/ico/ico-profile-en.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-so {
  display: block;
  background: url('./assets/ico/ico-profile-so.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-ma {
  display: block;
  background: url('./assets/ico/ico-profile-ma.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-pl {
  display: block;
  background: url('./assets/ico/ico-profile-pl.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-no {
  display: block;
  background: url('./assets/ico/ico-profile.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-profile-se {
  display: block;
  background: url('./assets/ico/ico-profile-se.svg') no-repeat 50% 50% / cover;
  cursor: pointer;

  &:active {
    transform: scale(1);
  }
}

.ico-exit-app {
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  background: url('./assets/ico/ico-exit-app.svg') no-repeat 50% 50% / cover;
}
