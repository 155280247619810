@import './variables';

body {
  padding: 0px;
  margin: 0px;
  background-color: $body-bg-color;
  color: $text-color;
  font-family: $app-primary-font;
}

.list-reset {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f2f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar {
  background-color: #f2f1f1;
  width: 12px;
}

.katex {
  font-family: $app-primary-font !important;
  font-size: inherit;
  font-weight: inherit;
}

.katex .mathnormal {
  font-family: $app-primary-font !important;
  font-style: normal;
}

//div:has(span.katex) {
//  text-align: center;
//}

.tiki-word:has(span.katex) {
  height: auto;
  padding-bottom: 3px;
  padding-top: 3px;
}
