@import '~src/scss/variables';

.text-content {
  font-size: 28px;
  font-weight: $font-weight-normal;

  .tooltip {
    font-weight: $font-weight-normal;
    text-decoration: underline;
    position: relative;
  }

  // .tooltip-color-en {
  //   color: #bbc7eb;
  // }

  // .tooltip-color-so {
  //   color: #004a99;
  // }

  // .tooltip-color-ma {
  //   color: #006699;
  // }

  // .tooltip-color-pl {
  //   color: #fecc00;
  // }

  // .tooltip-color-no {
  //   color: $main-accent-color;
  // }

  .tooltip .tooltiptext {
    visibility: hidden;
    display: none;
    background-color: black;
    color: #fff;
    font-size: 15px;
    text-align: center;
    padding: 5px 10px;
    border-radius: 6px;
    //margin-left: 10px;
    //margin-top: -25px;
    position: absolute;
    z-index: 1;
  }

  .tooltip:hover .tooltiptext {
    visibility: visible;
    display: unset;
  }

  /*.tooltip .tooltiptext::after {
    content: ' ';
    position: absolute;
    top: 100%;
    left: 27%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }*/

  .positionTop {
    bottom: 95%;
    left: 10%;
  }

  .positionBottom {
    top: 95%;
    left: 10%
  }

  .positionLeft {
    right: 100%;
  }

  .positionTop::after {
    top: 100%;
    left: 27%;
    content: ' ';
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }

  .positionBottom::after {
    bottom: 100%;
    left: 27%;
    content: ' ';
    position: absolute;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }

  .positionLeft::after {
    left: 100%;
    top: 27%;
    content: ' ';
    position: absolute;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent black;
  }

  p {
    margin: 0px 0px 30px 0px;
  }
}
